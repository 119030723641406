<template>
  <div>
    <ol class="breadcrumb" >
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item">
        <a href="/admin/booth/lists">Quản lý Mặt bằng </a>
      </li>

      <li class="breadcrumb-item">Thêm mới Mặt bằng</li>
    </ol>

    <form method="post" @submit.prevent="mySubmit" id="sendform">
      <div class="container">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#home"
              >Tiếng Việt
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#menu1">English </a>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div id="home" class="container tab-pane active">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Thông tin </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                   
                    <div class="row">

                       <div class="col-sm-10" style="display: none;">
                        <div class="form-group">
                          <label for="boothName"
                            > Gian hàng  <span class="text-danger">*</span></label
                          >
                          <select name="type" v-model="Form.type" class="form-control">
                                <option value="0">Thuê mặt bằng </option>
                                 <option value="1">Quầy vé  </option>
                          </select>
                        </div>
                      </div>


                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="boothName"
                            > Khu <span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            name="area"
                            class="form-control"
                          />
                          <p class="text-danger">{{ error.boothName }}</p>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="boothName"
                            >Tên<span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            name="boothName"
                            class="form-control"
                          />
                          <p class="text-danger">{{ error.boothName }}</p>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="picturefile"
                            >Hình ảnh đại diện<span class="text-danger"
                              >*</span
                            ></label
                          >
                          <input
                            type="file"
                            accept="image/*"
                            name="picturefile"
                            class="form-control main_picture"
                            @change="onPicturefile"
                          />

                          <div class="view_picture_one" v-if="img_one != ''">
                            <img :src="img_one" style="max-width: 400px" />
                          </div>
                          <p class="text-danger">{{ error.picturefile }}</p>
                        </div>
                      </div>

                       <div class="col-sm-10">
                        <div class="form-group">
                          <label for="content">Tên khách hàng </label>
                           <input
                            type="text"
                            name="content"
                            class="form-control"
                          />
                        </div>
                      </div>

                      <div class="col-sm-10">
                            <div class="form-group">
                             <div class="form-check form-check-inline">
                              

                              <input
                                type="checkbox"
                                name="flagcustomer"
                                class="form-check-input ml-3"
                                 v-model="from.flagCustomer"
                                value="1"
                              />

                              <label class="form-check-label ml-3" >
                                HIện thị
                              </label>
                            </div>
                          </div>

                      
                      </div>


                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="ranger"
                            >Vị trí<span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            name="ranger"
                            class="form-control"
                          />
                          <p class="text-danger">{{ error.ranger }}</p>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="height"
                            >Chiều dài<span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            name="height"
                            class="form-control"
                          />
                          <p class="text-danger">{{ error.height }}</p>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="width"
                            >Chiều rộng<span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            name="width"
                            class="form-control"
                          />
                          <p class="text-danger">{{ error.width }}</p>
                        </div>
                      </div>
                      
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="acreage"
                            > Diện tích <span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            name="acreage"
                            class="form-control"
                          />
                          <p class="text-danger">{{ error.acreage }}</p>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="status">Trạng thái </label>
                          <input
                            type="text"
                            name="descriptionstatus"
                            class="form-control"
                          
                          />
                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="menu1" class="container tab-pane fade">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Banner </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="boothName_EN"
                            >Name<span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            name="boothName_EN"
                            class="form-control"
                          />
                          <p class="text-danger">{{ error.boothName_EN }}</p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-footer"
            style="width: 90%; position: fixed; bottom: 0"
          >
            <button
              type="submit"
              class="btn btn-sm btn-primary"
              v-if="!is_load"
            >
              <i class="fad fa-save"></i> Save
            </button>
            <h5 v-else>Loading...</h5>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      img_one: "",
      is_load: false,
     
      error: {
        boothName: "",
        picturefile: "",
        boothName_EN: "",
        height: "",
        width: "",
        acreage: "",
        area: "",
        ranger: "",
      },
      Form:{
        type:0
      },
      editorConfig: window.editorConfig,
      editorConfig_EN: window.editorConfig_EN,
    };
  },
  mounted() {},

  methods: {
    onNamespaceLoaded( CKEDITOR ) {
        CKEDITOR.plugins.addExternal( 'video', '/layout/ckeditor/video/plugin.js' );
    },
    alertError() {
      let tt = "";
      for (let i = 0; i < Object.values(this.error).length; i++) {
        if (Object.values(this.error)[i]) {
          tt =
            tt +
            "<ul style='text-align:left;'><li>" +
            Object.values(this.error)[i] +
            "</li></ul>";
        }
      }
      return tt;
    },
    mySubmit: function () {
      let myForm = document.getElementById("sendform");
      let formData = new FormData(myForm);
      for (var pair of formData) {
        if (pair[0] == "boothName") {
          if (pair[1] == "") {
            this.error.boothName = "Vui lòng nhập tên.";
          } else {
            this.error.boothName = "";
          }
        }
        if (pair[0] == "boothName_EN") {
          if (pair[1] == "") {
            this.error.boothName_EN = "Vui lòng nhập tên - Tiếng Anh.";
          } else {
            this.error.boothName_EN = "";
          }
        }
        if (pair[0] == "height") {
          if (pair[1] == "") {
            this.error.height = "Vui lòng nhập chiều dài.";
          } else {
            this.error.height = "";
          }
        }
        if (pair[0] == "width") {
          if (pair[1] == "") {
            this.error.width = "Vui lòng nhập chiều rộng.";
          } else {
            this.error.width = "";
          }
        }
        if (pair[0] == "area") {
          if (pair[1] == "") {
            this.error.area = "Vui lòng nhập diện tích.";
          } else {
            this.error.area = "";
          }
        }
        if (pair[0] == "acreage") {
          if (pair[1] == "") {
            this.error.acreage = "Vui lòng nhập liên hệ.";
          } else {
            this.error.acreage = "";
          }
        }
        if (pair[0] == "ranger") {
          if (pair[1] == "") {
            this.error.ranger = "Vui lòng nhập vị trí.";
          } else {
            this.error.ranger = "";
          }
        }
        if (pair[0] == "picturefile") {
          if (pair[1].name == "") {
            this.error.picturefile = "Vui lòng nhập hình.";
          } else {
            this.error.picturefile = "";
          }
        }
      }
      if (
        this.error.boothName == "" &&
        this.error.picturefile == "" &&
        this.error.boothName_EN == "" &&
        this.error.height == "" &&
        this.error.width == "" &&
        this.error.acreage == "" &&
        this.error.area == "" &&
        this.error.ranger == ""
      ) {
        

        formData.append("Area_EN", formData.get('area'));
        formData.append("Ranger_EN", formData.get('ranger'));
     
        formData.append("Width_EN", formData.get('width'));
        formData.append("Height_EN", formData.get('height'));
        formData.append("Acreage_EN", formData.get('acreage'));

        formData.append("content_EN", formData.get('content'));
        this.is_load = true;
        axios
          .post(window.my_api + "api/booth/create-or-update-booth", formData,
              {
              headers: window.my_header
            }
            )
          .then((res) => {
            if (res.status == 200) {
              this.$swal.fire({
                position: "top-end",
                icon: "success",
                title: "Thêm thành công.",
                showConfirmButton: false,
                timer: 1500,
              });
               this.$router.go(this.$router.currentRoute);
              document.getElementById("sendform").reset();
              this.img_one = "";
            } else {
              alert("Thêm không thành công, vui lòng liên hệ admin");
            }
            this.is_load = false;
          });
      } else {
        this.$swal.fire({
          icon: "warning",
          html: this.alertError(),
        });
      }
    },
    onPicturefile(e) {
      let _this = this;
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        _this.img_one = reader.result;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>